<template>
  <v-overlay
    :value="showLoading"
    v-bind="attr"
  >
    <v-progress-circular
      indeterminate
      size="64"
    />
  </v-overlay>
</template>

<script>
import { computed, defineComponent, reactive, toRefs, watch } from 'vue';
export default defineComponent({
  name: 'LoadingSpinner',
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },

  props: {
    modelValue: {
      type: Boolean,
      default: false
    },

    light: {
      type: Boolean,
      default: false,
    },

    dark: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['update:modelValue'],

  setup(props){
    const state = reactive({
      showLoading: props.modelValue,

      attr: computed(()=> {
        return {
          light: props.light,
          dark: props.dark
        };
      })
    });

    watch(()=> props.modelValue, (nv) => {
      state.showLoading = nv;
    });

    return {
      ...toRefs(state)
    };
  },
});
</script>
